export const destinations: IMenu[] = [
  { href: '/destination/chiangmai', label: 'Menu.CITY_CNX' },
  { href: '/destination/bangkok', label: 'Menu.CITY_BKK' },
  { href: '/destination/pattaya', label: 'Menu.CITY_PTY' },
  { href: '/destination/phuket', label: 'Menu.CITY_PKT' }
]

export const packages: IMenu[] = [
  { href: '/package/2hour_h', label: 'Menu.SPA_PACKAGES_MENU_CAP' },
  { href: '/package/2hhour_h', label: 'Menu.SPA_PACKAGES_MENU1_CAP' },
  { href: '/package/3hour_h', label: 'Menu.SPA_PACKAGES_MENU3_CAP' }
]

export const massages: IMenu[] = [
  { href: '/massage/signature', label: 'Menu.SIGNATUE_MASSAGE_MENU' },
  { href: '/massage/traditional', label: 'Menu.TRADITIONAL_MASSAGE_MENU' },
  { href: '/massage/kids', label: 'Menu.KIDS_TREATMENT_MENU' }
]

export const treatments: IMenu[] = [
  { href: '/treatment/bodyscrubs', label: 'Menu.BODY_SCRUBS_MENU' },
  { href: '/treatment/bodywraps', label: 'Menu.BODY_WRAPS_MENU' },
  { href: '/treatment/facial', label: 'Menu.FACIAL_TREATMENTS_MENU' },
  { href: '/treatment/hydrotherapy', label: 'Menu.HYDROTHERAPY_MENU' }
]

export const gifts: IMenu[] = [
  { href: '/perfectgift/giftcertificate', label: 'Menu.MENU_BUYGIFT' },
  { href: '/onlinegift/redeem', label: 'Menu.MENU_REDEEMGIFT' }
]

export const abouts: IMenu[] = [
  { href: '/whoweare', label: 'Menu.FOOTER_AWARDS' },
  { href: '/philosophy', label: 'Menu.MENU_CONCEPT' },
  { href: '/blog', label: 'Menu.MENU_BLOG' },
  { href: '/newsevent', label: 'Menu.MENU_NEWS_EVENT' },
  { href: '/philosophy/ourpeople', label: 'Menu.FOOTER_OURPP' },
  { href: '/work', label: 'Menu.FOOTER_WORKWITHUS' },
  { href: '/FAQs', label: 'Menu.FOOTER_YOURVISIT' },
  { href: '/privacy-policy', label: 'Menu.PRIVACY_AND_POLICY' },
  { href: '/contact', label: 'Menu.MENU_CONTACT_US' }
]
