import { create } from 'zustand'

import { destinations } from '@/constants/menu'

type States = {
  destinations: IMenu[]
}

type Actions = {
  setDestinations: (payload: IMenu[]) => void
}

export const useCommonStore = create<States & Actions>((setState) => ({
  // STATE's
  destinations,

  // ACTION's
  setDestinations: (payload) => setState({ destinations: payload })
}))
